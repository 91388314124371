import toStartCase from 'lodash.startcase'
import { ROUTE_LABEL, RouteName, ROUTE_NAME } from '~/types'

const slugToString = (slug: string) => {
  const words = slug.split('-')

  return words
    .map((word, index) => (index === 0 ? toStartCase(word) : word))
    .join(' ')
}

type BreadcrumbPath = {
  path: string
  label: string
  url: string
}

const getLabelForRouteName = (route: string) => {
  if (route in ROUTE_LABEL) {
    return ROUTE_LABEL[route as RouteName]
  }

  return decodeURI(route)
}

export const getBreadcrumbPaths = (route: string) => {
  const parts = route.replace(/^\/|\/$/g, '')?.split('/') || []

  const breadcrumbParts = parts.reduce((acc, path) => {
    const label =
      path in ROUTE_NAME ? getLabelForRouteName(path) : slugToString(path)

    return acc.concat([
      {
        path,
        label,
        url: acc
          .map(({ path }) => `/${path}`)
          .concat(`/${path}`)
          .join(''),
      },
    ])
  }, [] as BreadcrumbPath[])

  if (breadcrumbParts[0].path === 'category') {
    breadcrumbParts[0].url = '/'
  }

  return breadcrumbParts
}
