<script setup lang="ts">
import {
  Box,
  ChevronRightIcon,
  Heading,
  Inline,
  Text,
} from '@betashares/web-ui-components'

const route = useRoute()

const breadcrumbPaths = computed(() => getBreadcrumbPaths(route.path))
</script>
<template>
  <Inline vertical-align="center">
    <template v-for="(item, index) in breadcrumbPaths" :key="index">
      <Inline vertical-align="center">
        <template v-if="index < breadcrumbPaths.length - 1">
          <NuxtLink :to="item.url">
            <Text size="lg" tone="medium-emphasis">
              {{ item.label }}
            </Text>
          </NuxtLink>
          <Box padding-x="2xs">
            <ChevronRightIcon />
          </Box>
        </template>
        <template v-else>
          <Heading size="lg" tone="high-emphasis" max-lines="1">
            {{ item.label }}
          </Heading>
        </template>
      </Inline>
    </template>
  </Inline>
</template>
